import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import AccountSettings from './content/pages/Setting';
import AccountSettingsS3 from './content/pages/Setting';
import ProtectedRoute, { SessionMaintained } from './components/ProtectedRoute';
import { AmacUrl, Page } from './common/routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

//Authentication
const Authentication = Loader(
  lazy(() => import('src/content/authentication/login2'))
);
const Register = Loader(
  lazy(() => import('src/content/authentication/register'))
);
const AWSauth = Loader(
  lazy(() => import('src/content/authentication/Awsauth'))
);
const AccountCreated = Loader(
  lazy(() => import('src/content/authentication/Accountcreated'))
);
const Forgetemail = Loader(
  lazy(
    () => import('src/content/authentication/forgetpassword/forgetcodeemail')
  )
);
const Forgetauth = Loader(
  lazy(() => import('src/content/authentication/forgetpassword/forgetcode'))
);

//Dashboard
const Home = Loader(lazy(() => import('src/content/dashboard/SearchUserClickHouse')));

//Pages
const Analytics = Loader(lazy(() => import('src/content/dashboard/Analytics')));
const UsersManagement = Loader(lazy(() => import('src/content/dashboard/UserManagement')));
const VotersListing = Loader(lazy(() => import('src/content/dashboard/VotersListing')));
const SearchUserDynamoDB = Loader(lazy(() => import('src/content/dashboard/SearchUserDynamoDB')));
const SearchUserClickHouse = Loader(lazy(() => import('src/content/dashboard/Home')));
const LoginLogs = Loader(lazy(() => import('src/content/dashboard/Loginlogs')));
const VotersListingLogs = Loader(lazy(() => import('src/content/dashboard/VotersListingLogs')));


// Status Pages
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

//Old Pages

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: (
      <SessionMaintained>
        <BaseLayout />
      </SessionMaintained>
    ),
    children: [
      {
        path: '/',
        element: <Authentication />
      },
      {
        path: Page.Login,
        element: <Authentication />
      },
      // {
      //   path: 'test',
      //   children: [
      //     {
      //       path: '/',
      //       element: <TestS3 />
      //     }
      //   ]
      // },
      {
        path: Page.Register,
        children: [
          {
            path: '/',
            element: <Register />
          }
        ]
      },
      {
        path: `${Page.Awsauth}/:authemail`,
        children: [
          {
            path: '/',
            element: <AWSauth />
          }
        ]
      },
      {
        path: Page.AccountCreated,
        children: [
          {
            path: '/',
            element: <AccountCreated />
          }
        ]
      },
      {
        path: Page.Forget,
        children: [
          {
            path: 'password',
            element: <Forgetemail />
          },
          {
            path: 'authcode',
            element: <Forgetauth />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '*',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      // {
      //   path: AmacUrl.Rules,
      //   children: [
      //     {
      //       path: '/',
      //       element: <CaseSettingsS3 />
      //     }
      //   ]
      // }
    ]
  },

  {
    path: '/',
    element: (
      <ProtectedRoute>
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '/',
        element: <Navigate to={Page.Dashboard} replace />
      },
      {
        path: `${Page.Dashboard}/`,
        element: <Home />
      },
      {
        path: `${Page.Analytics}/`,
        element: <Analytics />
      },
      {
        path: `${Page.UsersManagement}/`,
        element: <UsersManagement />
      },
      {
        path: `${Page.LoginLogs}/`,
        element: <LoginLogs />
      },
      {
        path: `${Page.VotersListingLogs}/`,
        element: <VotersListingLogs />
      },
      {
        path: `${Page.VotersListing}/`,
        element: <VotersListing />
      },
      {
        path: `${Page.Voters}/`,
        element: <SearchUserDynamoDB />
      },
      {
        path: `${Page.SearchUserClickHouse}/`,
        element: <SearchUserClickHouse />
      }     

      
      
      // {
      //   path: `${Page.AdvanceSearch}`,
      //   element: <AdvanceSearch />
      // }
      // ,
      // {PSTPIIDocuments
      //   path: 'Test',
      //   element: <Test />
      // }
    ]
  },

  //Old Paths
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboards/crypto" replace />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/management/transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '/',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: 'components',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/components/buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
