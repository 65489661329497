import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setGlobalState, useGlobalState } from './state/globalstate';
import IdleTimer from './session/idle';
// import { version } from 'react';
import Axios, { API } from './common/api';
import * as crypto from 'crypto';
import React from 'react';
import { Widget, addResponseMessage, toggleMsgLoader } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import CacheBuster from 'react-cache-buster';
import version from '../package.json';
// import Loading from './loading';

// import logo from './logo.svg';

declare global {
  interface Window {
    analytics: any;
  }
}

const App = () => {
  // // ////console.log(version);
  const isProduction = process.env.NODE_ENV === 'production';
  //console.log('Environment')
  //console.log(isProduction)
  //console.log(version.version)
  const [keyword, setKeyword] = useState<string>('');
  const value = { keyword, setKeyword };
  const content = useRoutes(routes);
  var CryptoTS = require('crypto-ts');

  const [modalmessage] = useGlobalState('modalmessage');

  if (modalmessage) {
    modalmessage;
    setGlobalState('modalmessage', null);
  }

  const [loggedin] = useGlobalState('loggedin');

  //Remove console messages
  if (process.env.NODE_ENV === 'production') {
    //console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
    console.info = () => {};
    console.group = () => {};
    console.groupCollapsed = () => {};
    console.trace = () => {};
  } else if (process.env.NODE_ENV === 'development') {
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
    console.info = () => {};
    console.group = () => {};
    console.groupCollapsed = () => {};
    console.trace = () => {};
  }

  useEffect(() => {


    // getCountries
    const getCountries = async () => {
      try {
        await Axios.get(API.getCountries).then((res) => {
          // store the data into our books variable
          const countryArray = JSON.parse(res.data[0].country)
          //console.log('countryArray ===')
          //console.log(countryArray)
          setGlobalState('countryListing', countryArray);
        });
      } catch (err) {
        console.log(err)
      }
    };
    getCountries();

    // Religion Classifications
    const ReligionClassifications = async () => {
      try {
        await Axios.get(API.ReligionClassifications).then((res) => {
          // store the data into our books variable
          
          const ReligionArray = res.data
          console.log(res.data)
          //console.log('countryArray ===')
          //console.log(countryArray)
          setGlobalState('religionListing', ReligionArray);
        });
      } catch (err) {
        console.log(err)
      }
    };
    ReligionClassifications();

  }, []);

  // useEffect(() => {
  //   addResponseMessage('Welcome to this AMAC Voters Q chat!');
  // }, []);

  // const handleNewUserMessage = async (newMessage) => {
  //   let chatResponse = '';
  //   toggleMsgLoader();
  //   ////console.log(`New message incoming! ${newMessage}`);
  //   const params = { question: encodeURIComponent(newMessage) };
  //   try {
  //     await Axios.get(API.mlrcBedrock, {
  //       params
  //     }).then((res) => {
  //       // store the data into our books variable
  //       ////console.log(res.data.response);
  //       chatResponse = res.data.response;
  //       // let chatResponse = res.data;
  //     });
  //   } catch (err) {
  //     // setGlobalState('ErrorFoundGlobal', false);
  //     // ////console.log(err);
  //   }

  //   addResponseMessage(chatResponse);
  //   toggleMsgLoader();
  //   // Now send the message throught the backend API
  // };

  return (
    <CacheBuster
      currentVersion={version.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
        <IdleTimer />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <div className="App">
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            // profileAvatar={logo}
            title="AMAC Voters Q"
            // subtitle="And my cool subtitle"
          />
        </div> */}
      </LocalizationProvider>
    </ThemeProvider>
    </CacheBuster>
  );
};
export default App;
